import React from "react";
import {Typography} from "antd";

const {Title, Paragraph, Text} = Typography;


function Privacy() {
    return (
        <div style={{
            paddingTop: '100px',
            paddingBottom: "100px",
            width: "70%",
            margin: '0 auto'
        }}>
            <Title style={{
                textAlign: "center",
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB"
            }}>
                图所记隐私协议
            </Title>

            <Paragraph style={{
                textAlign: "right",
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
            }}>
                本协议生效日期：2024年10月20日
            </Paragraph>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                欢迎使用图所记（以下简称“本应用”）。我们非常重视您的隐私与个人信息的保护。为了确保您了解我们如何收集、使用、共享和保护您的个人信息，请仔细阅读本隐私协议（以下简称“本协议”）。使用本应用即表示您同意本协议的全部内容。
            </Paragraph>

            <Title level={2} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "20px",
            }}>
                一、我们收集的信息
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                在您使用本应用的过程中，我们可能会收集以下类型的信息：
            </Paragraph>
            <Paragraph style={{
                fontSize: "16px",
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB"
            }}>
                1. <strong>个人身份信息</strong>：当您注册账号或使用本应用的某些功能时，可能会要求您提供姓名、电子邮件地址、电话号码等信息。
                <br/>
                2. <strong>使用信息</strong>：包括但不限于您对本应用的使用记录、浏览记录、点击行为等信息。
                <br/>
                3. <strong>设备信息</strong>：我们可能会收集与您使用的设备相关的信息，包括设备型号、操作系统版本、设备标识符（如IMEI、MAC地址等）、IP地址及位置信息等。
                <br/>
                4. <strong>图片和多媒体信息</strong>：作为图所记的核心功能，您可能会通过本应用上传或保存图片、照片等多媒体内容。本应用仅在您明确授权的前提下访问这些文件。
            </Paragraph>

            <Title level={2} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "20px",
            }}>
                二、我们如何使用信息
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                我们收集的信息将用于以下用途：
            </Paragraph>
            <Paragraph style={{
                fontSize: "16px",
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB"
            }}>
                1. 提供、维护、改进和优化本应用的服务和功能；
                <br/>
                2. 用于个性化服务，如基于用户偏好的内容推荐；
                <br/>
                3. 处理您的请求、反馈或问题，提供客户支持；
                <br/>
                4. 防止违法活动、欺诈行为或安全事件，确保本应用的安全与稳定；
                <br/>
                5. 在获得您的同意后，向您发送产品更新、活动及其他推广信息。
            </Paragraph>

            <Title level={2} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "20px",
            }}>
                三、信息共享与披露
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                我们严格遵守法律法规的规定，不会未经您的同意向任何第三方分享、出售或披露您的个人信息，但在以下情况下除外：
            </Paragraph>
            <Paragraph style={{
                fontSize: "16px",
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB"
            }}>
                1. <strong>法律要求</strong>：如果我们有理由相信披露您的信息是出于法律要求、司法程序或政府机关的要求。
                <br/>
                2. <strong>业务转让</strong>：如本应用涉及合并、收购、重组或资产出售，您的个人信息可能作为交易的一部分被转移，但我们会确保该等信息的保密性。
                <br/>
                3. <strong>第三方服务提供商</strong>：为向您提供更好的服务，我们可能会与可信的第三方服务提供商共享必要的信息，这些服务商将仅在提供服务所需的范围内使用您的信息，并需遵守严格的保密协议。
            </Paragraph>

            <Title level={2} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "20px",
            }}>
                四、信息的安全性
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                在信息安全性方面，图所记App始终将用户隐私保护和数据安全放在首位。我们采用了先进的加密技术，确保用户的个人信息在传输和存储过程中的安全性。同时，图所记严格遵循相关法律法规，定期进行安全审计和漏洞修复，以防范潜在的安全威胁。此外，我们与业内领先的安全服务提供商合作，部署多层防护措施，保护用户数据免受未经授权的访问。通过持续的技术创新和安全策略的优化，图所记致力于为用户提供一个安全、可靠的使用环境。
            </Paragraph>

            <Title level={2} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "20px",
            }}>
                五、您对信息的控制
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                您可以随时通过本应用的设置页面查看、更正或删除您的个人信息，并且在必要时，您有权要求我们停止对您的信息的处理。对于敏感权限（如相机、存储访问等），您也可以在设备的设置中手动关闭这些权限。
            </Paragraph>

            <Title level={2} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "20px",
            }}>
                六、第三方SDK
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                为了提供更好的服务体验，优化应用性能，我们在应用中集成了第三方SDK，以实现特定功能。具体信息如下：
            </Paragraph>

            <Title level={3} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "18px",
            }}>
                1. Bugly（腾讯Bugly）
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                <strong>用途：</strong>用于应用的错误监控、崩溃统计和修复，以提升应用的稳定性。<br/>
                <strong>收集信息：</strong>Bugly会收集设备信息（如设备型号、系统版本等）、应用崩溃日志、用户的操作行为等，以分析应用中的崩溃问题。<br/>
                <strong>隐私政策：</strong>Bugly将依据其隐私政策对数据进行处理，更多信息请参见
                <a href="https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56" target="_blank" rel="noopener noreferrer">
                    Bugly隐私政策
                </a>。
            </Paragraph>

            <Title level={3} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "18px",
            }}>
                2. ATAuthSDK（阿里云一键登录SDK）
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                <strong>用途：</strong>用于提供一键登录功能，简化用户的登录流程，提升用户体验。<br/>
                <strong>收集信息：</strong>ATAuthSDK可能会收集设备信息（如设备标识、运营商信息、网络状态等），用于认证用户并完成一键登录。<br/>
                <strong>隐私政策：</strong>ATAuthSDK将依据其隐私政策对数据进行处理，更多信息请参见
                <a href="https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112211045_86198.html?spm=a2c4g.11186623.0.0.5bef70eaTrMKWj" target="_blank"
                   rel="noopener noreferrer">
                    阿里云隐私政策
                </a>。
            </Paragraph>

            <Title level={2} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "20px",
            }}>
                七、未成年人保护
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                我们特别重视未成年人的个人信息保护。如果您是18周岁以下的未成年人，请在父母或监护人的指导下使用本应用，并确保父母或监护人同意本协议。
            </Paragraph>

            <Title level={2} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "20px",
            }}>
                八、隐私协议的变更
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                我们可能会根据法律、技术更新或业务需求不时修订本协议。协议的任何更新将会通过本应用发布，并标明最近的修订日期。请您定期查阅，以确保您了解我们最新的隐私政策。您继续使用本应用即表示您接受这些修改。
            </Paragraph>

            <Title level={2} style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "20px",
            }}>
                九、联系我们
            </Title>

            <Paragraph style={{
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB",
                fontSize: "16px",
            }}>
                如果您对本协议有任何疑问或建议，或者希望行使您的个人信息相关权利，您可以通过以下方式联系我们：
            </Paragraph>

            <Paragraph style={{
                fontSize: "16px",
                color: "#252F2C",
                fontFamily: "LXGWWenKaiGB"
            }}>
                电子邮件: contact@charmanda.com
            </Paragraph>
        </div>
    )
}

export default Privacy;
