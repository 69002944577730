import {Divider} from "antd";
import React from "react";
import Pricing from "./Pricing";
import Intro from "./Intro";
import NotistLayout from "./NotistLayout";
import NotistManagement from "./NotistManagement";
import NotistEditor from "./NotistEditor";


const NotistContent = () => {
    return (
        <div style={{paddingTop: '100px', paddingBottom: "100px", width: "70%", margin: '0 auto'}}>
            <Intro/>
            <Divider style={{margin: '100px'}}/>
            <NotistLayout/>
            <Divider style={{margin: '100px'}}/>
            <NotistManagement/>
            <Divider style={{margin: '100px'}}/>
            <NotistEditor/>
            <Divider style={{margin: '100px'}}/>
            <Pricing/>
        </div>
    )
}

export default NotistContent;
