import React from 'react';
import {Flex, Avatar, Space, Button, message} from "antd";
import scrollToLocation from "../../util";
import { useNavigate } from "react-router-dom";

const NotistHeader = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate()

    return (
        <div style={{width: '80%', margin: "0 auto"}}>
            {contextHolder}
            <Flex justify="space-between">
                <Space size={16} align="middle">
                    <Avatar size={48} shape={"square"}
                            src={"https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/notist100.png"}/>
                    <h2>图所记</h2>
                </Space>

                <Space size={6} align="middle">
                    <Button type="text"
                            className="underline-button"
                            style={{color: "#FAFAF9", fontSize: "18px", padding: "15px"}}
                            onClick={() => {
                                messageApi.info("即将上线，敬请期待！")
                            }}
                    >
                        下载
                    </Button>

                    <Button type="text"
                            className="underline-button"
                            style={{color: "#FAFAF9", fontSize: "18px", padding: "15px"}}
                            onClick={() => scrollToLocation("pricing")}
                    >
                        价格
                    </Button>

                    <Button type="text"
                            className="underline-button"
                            style={{color: "#FAFAF9", fontSize: "18px", padding: "15px"}}
                            onClick={() => {
                                window.open('mailto:contact@charmanda.com');
                            }}
                    >
                        联系我们
                    </Button>

                    <Button type="text"
                            className="underline-button"
                            style={{color: "#FAFAF9", fontSize: "18px", padding: "15px"}}
                            onClick={() => {
                                navigate("/privacy")
                            }}
                    >
                        隐私协议
                    </Button>
                </Space>
            </Flex>
        </div>
    )
}

export default NotistHeader
