import {Avatar, Col, Image, Row, Typography} from "antd";
import React from "react";

const {Title} = Typography;

const NotistEditor = () => {
    return (
        <div>
            <Row align="middle">
                <Col span={24} align="middle">
                    <Title level={3} style={{
                        fontFamily: "LXGWWenKaiGB",
                        textAlign: "center",
                        color: "#252F2C",
                        lineHeight: "64px"
                    }}>
                        <Avatar src={"https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/easy.png"} alt="" />
                        「<span style={{color: "#FF6A5F"}}>主题</span>」
                        「<span style={{color: "#FF6A5F"}}>标签</span>」
                        「<span style={{color: "#FF6A5F"}}>搜索</span>」<br/>
                        帮助你轻松查找和整理笔记
                    </Title>
                </Col>
            </Row>

            <Row>
                <Col span={8} type="flex" align="middle">
                    <Image width={300}
                           preview={false}
                           src="https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/notist-summary.png"
                    />
                </Col>

                <Col span={8} type="flex" align="middle">
                    <Image width={300}
                           preview={false}
                           src="https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/notist-gallery.png"
                    />
                </Col>

                <Col span={8} type="flex" align="middle">
                    <Image width={300}
                           preview={false}
                           src="https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/notist-editor.png"
                    />
                </Col>
            </Row>
        </div>
    )
}

export default NotistEditor
