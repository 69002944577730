import {Button, Space} from "antd";

const ContactFooter = () => {
    return (
        <Space direction="vertical" size={0}>
            <Button type="text"
                    style={{color: "#686D76", fontSize: "12px"}}
                    onClick={() => {
                        window.open('mailto:contact@charmanda.com');
                    }}
            >
                反馈
            </Button>
        </Space>
    )
}

export default ContactFooter
