import {Space, Typography} from "antd";
import React from "react";

const {Title} = Typography;

const Intro = () => {
    return (
        <Space id="intro" direction={"vertical"} style={{width: '100%', justifyContent: 'center'}}>
            <Title style={{
                textAlign: "center",
                fontFamily: "LXGWWenKaiGB",
                paddingTop: "60px",
                paddingBottom: "10px",
                color: "#252F2C"
            }}>
                是你的<span style={{color: "#FF6A5F"}}>笔记本</span>，更是你的<span
                style={{color: "#FF6A5F"}}>知识图谱</span>
            </Title>

            <Title
                level={3}
                style={{
                    textAlign: "center",
                    fontFamily: "LXGWWenKaiGB",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    color: "#252F2C"
                }}
            >
                内化的知识才是你的认知，结构化的思考才有深度
            </Title>
        </Space>
    )
}

export default Intro
