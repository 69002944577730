import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.css'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Privacy from "./Privacy";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <Routes>
            <Route path="/" element={<App/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
        </Routes>
    </Router>
);
