import {Avatar, Col, Image, Row, Space, Typography} from "antd";
import React from "react";
import ProductFooter from "./ProductFooter";
import ContactFooter from "./ContactFooter";
import AboutFooter from "./AboutFooter";

const {Title} = Typography;

const NotistFooter = () => {
    return (
        <div style={{width: '70%', margin: '0 auto'}}>
            <Row>
                <Col span={6}>
                    <Space size={16} align="baseline">
                        <Avatar size={48} shape={"square"}
                                src={"https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/notist100.png"}/>
                        <h2>图所记</h2>
                    </Space>
                </Col>

                <Col span={6}>
                    <Space direction={"vertical"} align="baseline">
                        <Title level={5} style={{fontFamily: "LXGWWenKaiGB"}}>
                            加入微信社区
                        </Title>
                        <Image
                            src="https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/notist-group.png"
                            preview={false}
                            width={100}
                        />
                    </Space>
                </Col>

                <Col span={12}>
                    <Row>
                        <Col span={8}>
                            <Space direction={"vertical"}>
                                产品相关
                                <ProductFooter/>
                            </Space>
                        </Col>

                        <Col span={8}>
                            <Space direction={"vertical"}>
                                服务支持
                                <ContactFooter/>
                            </Space>
                        </Col>

                        <Col span={8}>
                            <Space direction={"vertical"}>
                                关于
                                <AboutFooter/>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <a
                        href="https://beian.miit.gov.cn/" target="_blank"
                        style={{color: "#686D76", fontSize: "12px"}}
                    >
                        浙ICP备2024117382号-1
                    </a>
                </Col>
            </Row>
        </div>
    )
}


export default NotistFooter
