import {Avatar, Col, Row, Space, Typography} from "antd";
import ReactPlayer from "react-player/lazy";
import React from "react";

const {Title} = Typography;

const NotistManagement = () => {
    return (
        <Row align="middle">
            <Col span={12} type="flex" align="middle">
                <ReactPlayer
                    url="https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/notist-management.mp4"
                    playing={true}
                    loop={true}
                    muted={true}
                    width={400}
                    height={590}
                />
            </Col>

            <Col span={12} type="flex" align="middle">
                <Space direction={"vertical"}>
                    <Row align="middle" justify="center">
                        <Col>
                            <Avatar shape="square" src={"https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/management.png"}/>
                        </Col>
                        <Col>
                            <Title level={2} style={{
                                textAlign: "center",
                                fontFamily: "LXGWWenKaiGB",
                                padding: "10px",
                                margin: "0",
                                color: "#FF6A5F"
                            }}>
                                灵活整理知识体系
                            </Title>
                        </Col>
                    </Row>

                    <Title level={3} style={{
                        fontFamily: "LXGWWenKaiGB",
                        textAlign: "center",
                        paddingTop: "10px",
                        color: "#252F2C"
                    }}>
                        知识体系并不是一成不变的，就像树的成长需要修枝抹芽
                    </Title>

                    <Title level={3} style={{
                        fontFamily: "LXGWWenKaiGB",
                        textAlign: "center",
                        paddingTop: "10px",
                        color: "#686D76",
                        lineHeight: "40px",
                    }}>
                        主动整理就是理解内化的过程<br/>
                        是被动学习到<span style={{color: "#FF6A5F"}}>主动学习</span>的必然阶段
                    </Title>
                </Space>
            </Col>
        </Row>
    )
}

export default NotistManagement
