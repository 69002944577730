import {Avatar, Col, Image, Row, Space, Typography} from "antd";
import React from "react";

const {Title} = Typography;

const NotistLayout = () => {
    return (
        <Row align="middle" justify="center">
            <Col span={12} align="middle">
                <Space direction={"vertical"}>
                    <Row align="middle" justify="center">
                        <Col>
                            <Avatar shape="square" src={"https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/idea.png"}/>
                        </Col>
                        <Col>
                            <Title level={2} style={{
                                fontFamily: "LXGWWenKaiGB",
                                padding: "10px",
                                color: "#FF6A5F",
                                margin: 0
                            }}>
                                看得到，才学得到
                            </Title>
                        </Col>
                    </Row>

                    <Title level={3} style={{
                        fontFamily: "LXGWWenKaiGB",
                        textAlign: "center",
                        paddingTop: "10px",
                        color: "#252F2C"
                    }}>
                        知识积累像种一棵树，枝繁叶茂，开花结果
                    </Title>

                    <Title level={3} style={{
                        fontFamily: "LXGWWenKaiGB",
                        textAlign: "center",
                        paddingTop: "10px",
                        color: "#686D76",
                        lineHeight: "40px",
                    }}>
                        相比于<span style={{color: "#FF6A5F"}}>记</span>，
                        图所记更注重<span style={{color: "#FF6A5F"}}>理</span><br/>
                        对知识的整<span style={{color: "#FF6A5F"}}>理</span>，
                        对知识的<span style={{color: "#FF6A5F"}}>理</span>解<br/>
                        帮助你轻松的构建知识体系
                    </Title>
                </Space>
            </Col>
            <Col span={12} type="flex" align="middle">
                <Image width={300}
                       preview={false}
                       src="https://notist-website-public.oss-cn-hangzhou.aliyuncs.com/notist-layout.png"
                />
            </Col>
        </Row>
    )
}

export default NotistLayout
