import NotistContent from "./component/content/NotistContent";
import NotistHeader from "./component/header/NotistHeader";
import React, {useEffect} from "react";
import NotistFooter from "./component/footer/NotistFooter";
import {Layout} from "antd";

const {Header, Content, Footer} = Layout;

function App() {
    useEffect(() => {
        if (window.location.hash) {
            const element = document.getElementById(window.location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <Layout>

            <Header style={{
                color: "#FAFAF9",
                backgroundColor: "#465A54"
            }}>
                <NotistHeader/>
            </Header>

            <Content style={{
                backgroundColor: "#FAFAF9"
            }}>
                <NotistContent/>
            </Content>

            <Footer style={{
                textAlign: "center",
                color: "#252F2C",
                backgroundColor: "#EBEBE6"
            }}>
                <NotistFooter/>
            </Footer>

        </Layout>
    );
}

export default App;
