import {Space, Button, message} from "antd";
import scrollToLocation from "../../util";

const ProductFooter = () => {
    const [messageApi, contextHolder] = message.useMessage();

    return (
        <Space size={0} direction="vertical">
            {contextHolder}
            <Button type="text"
                    style={{color: "#686D76", fontSize: "12px"}}
                    onClick={() => {
                        scrollToLocation("intro")
                    }}
            >

                产品介绍
            </Button>

            <Button type="text"
                    style={{color: "#686D76", fontSize: "12px"}}
                    onClick={()=>{
                        messageApi.info("即将上线，敬请期待！")
                    }}
            >
                产品下载
            </Button>
        </Space>
    )
}

export default ProductFooter;
