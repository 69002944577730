import {Table, Typography} from "antd";

const {Title, Text} = Typography;

const Pricing = () => {
    const columns = [
        {
            title: "功能",
            dataIndex: "function",
            key: "function",
            align: "center",
            render: (text) =>
                (
                    <Text style={{fontFamily: "LXGWWenKaiGB"}}>
                        {text}
                    </Text>
                )
        },
        {
            title: "基础版",
            dataIndex: "basic",
            key: "basic",
            align: "center",
            render: (text) =>
                (
                    <Text style={{fontFamily: "LXGWWenKaiGB"}}>
                        {text}
                    </Text>
                )
        },
        {
            title: "专业版",
            dataIndex: "pro",
            key: "pro",
            align: "center",
            render: (text) =>
                (
                    <Text style={{fontFamily: "LXGWWenKaiGB"}}>
                        {text}
                    </Text>
                )
        }
    ]

    const data = [
        {
            key: 1,
            function: "笔记数量",
            basic: "不限",
            pro: "不限"
        },
        {
            key: 2,
            function: "知识图谱",
            basic: "支持",
            pro: "支持"
        },
        {
            key: 3,
            function: "多设备同步",
            basic: "支持",
            pro: "支持"
        },
        {
            key: 4,
            function: "主题数量",
            basic: '20',
            pro: "不限"
        },
        {
            key: 5,
            function: "存储空间",
            basic: '200MB',
            pro: '10GB'
        },
        {
            key: 6,
            function: "图片质量",
            basic: '压缩',
            pro: '原图'
        }
    ]

    return (
        <div id="pricing" style={{
            padding: '20px',
            width: '70%',
            margin: '0 auto',
        }}>
            <Title align="center" style={{fontFamily: "LXGWWenKaiGB"}}>
                产品版本
            </Title>

            <Title align="center"
                   level={4}
                   style={{
                       fontFamily: "LXGWWenKaiGB",
                       color: "#686D76",
                       paddingBottom: "40px"
                   }}
            >
                图所记基础版本所有功能终身免费且无广告，专业版在基础版本上提供更全面的知识管理能力<br/>
                您的支持和信任是我们坚持下去的动力！
            </Title>

            <Table
                bordered={true}
                pagination={false}
                columns={columns}
                dataSource={data}
            />
        </div>
    )
}

export default Pricing
